import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';

import { loadEnv } from './config/env';
import { initTracker } from 'config/tracking';

const ReduxProvider = lazy(() => import('./ReduxProvider'));

const init = async () => {

    await loadEnv();
    initTracker();
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<Suspense><ReduxProvider /></Suspense>);
};

init();
