import * as Sentry from '@sentry/browser';
import { getEnv } from '../config/env';
import { VERSIONS } from '../config/versions';

import { log } from '../utils/log';

let _isSentryEnabled = false;
export const init = () => {
    log('[Sentry] init');
    const { sentryDsn, mode } = getEnv();
    if (sentryDsn && !_isSentryEnabled) {
        Sentry.init({
            dsn: sentryDsn,
            environment: mode,
            autoSessionTracking: true,
            normalizeDepth: 10,

            beforeSend(event, hint) {
                const errorMessage = hint?.originalException?.message || '';
                // Chunks failed to load
                if (errorMessage.includes('Loading CSS chunk')) return null;
                if (errorMessage.includes('Loading chunk')) return null;
                // ResizeObserver bug
                if (errorMessage.includes('Maximum call stack size exceeded')) return null;
                if (errorMessage.includes('ResizeObserver loop limit exceeded')) return null;
                // React weirdos
                if (errorMessage.includes('Failed to execute \'removeChild\' on \'Node\'')) return null;
                if (errorMessage.includes('TypeError: t.forEach is not a function')) return null;
                if (errorMessage.includes('Node.removeChild: The node to be removed is not a child of this node')) return null;
                // Google Tag errors
                if (errorMessage.includes('Cannot redefine property: googletag')) return null;
                // Weirdos who save page locally
                if (errorMessage.includes('A history state object with URL \'file:')) return null;
                if (errorMessage.includes('SecurityError: Failed to execute \'replaceState\' on \'History')) return null;
                // Safari Extension
                if (errorMessage.includes('@safari-extension:')) return null;
                // Chrome extension
                if (errorMessage.includes('chrome-extension://')) return null;

                // Filter errors from devTools console
                return filterDevToolsConsoleErrors(event);
            },
            // Community ignoreErrors & DenyUrls + personal Hubspots  from https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/
            ignoreErrors: [
                // Random plugins/extensions
                'top.GLOBALS',
                // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                'originalCreateNotification',
                'canvas.contentDocument',
                'MyApp_RemoveAllHighlights',
                'http://tt.epicplay.com',
                'Can\'t find variable: ZiteReader',
                'jigsaw is not defined',
                'ComboSearch is not defined',
                'http://loading.retry.widdit.com/',
                'atomicFindClose',
                // Facebook borked
                'fb_xd_fragment',
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
                // reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268
                'bmi_SafeAddOnload',
                'EBCallBackMessageReceived',
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                'conduitPage',
                // Resize Observer
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications.'
            ],
            denyUrls: [
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Hubspot
                /api\.hubspot\.com/i,
                /forms\.hubspot\.com/i,
                /api\.hubapi\.com/i,
                /js\.hsleadflows\.net/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                // Files
                /^file:\/\//i,
            ],
            release: `${VERSIONS.version} - ${VERSIONS.admin}`,
        });
        _isSentryEnabled = true;
    }
};

export const setUser = user => {
    const useUser = {
        id: user.id,
        username: `${user.firstName} ${user.lastName}`,
        email: user.email,
        roles: user.roles,
        versions: VERSIONS
    };

    log('[Sentry] SetUserContext:', useUser);
    if (!_isSentryEnabled) return;

    Sentry.configureScope(scope => {
        scope.setUser(useUser);
    });
};

export const unsetUser = () => {

    log('[Sentry] UnsetUserContext');

    if (!_isSentryEnabled) return;

    Sentry.configureScope(scope => {
        scope.setUser(null);
    });
};

export const capture = error => {
    log('[Sentry] captureException:', error);
    if (!_isSentryEnabled) return;

    Sentry.captureException(new Error(error));
};

export const manualCapture = error => {
    log('[Sentry] manualCaptureException:', error);
    if (!_isSentryEnabled) return;

    Sentry.captureMessage(error);
};

// https://lightrun.com/answers/getsentry-sentry-javascript-dev-console-errors-are-thrown-to-sentry
const filterDevToolsConsoleErrors = event => {

    // Helpers
    const isSuspiciousError = errorType => ['syntaxerror', 'referenceerror', 'typeerror'].includes(errorType);
    const hasSuspiciousFrames = stackFrames => {
        const allSuspicious = stackFrames.every(isSuspiciousFrame);
        // Certain type errors will include the thrown error message as the second stack frame,
        // but the first will still follow the suspicious pattern.
        const firstSuspicious = stackFrames.length === 2 && isSuspiciousFrame(stackFrames[0]);
        return allSuspicious || firstSuspicious;
    };

    const isSuspiciousFrame = frame => {
        const url = window.location.href;
        return frame.function === '?' && (frame.filename === '<anonymous>' || frame.filename === url);
    };

    const originalException = event.exception?.values?.[0];

    // Console errors appear to always bubble up to `window.onerror` and to be unhandled.
    // So if, we don't have the original exception or the mechanism looks different,
    // we can early return the event.
    // (Note, this might change depending on the used framework, so feel free to remove this check.)
    if (
        !originalException ||
        !originalException.mechanism ||
        originalException.mechanism.type !== 'onerror' ||
        originalException.mechanism.handled
    ) {
        return event;
    }

    const stackFrames = originalException.stacktrace?.frames;
    const errorType = originalException.type?.toLowerCase();

    // If we don't have any information on error type or stacktrace, we have no information about the error
    // this is unlikely to happen but it doesn't appear to happen in console errors.
    // Hence, we can early return here as well.
    if (!stackFrames || !errorType) {
        return event;
    }

    // For simple console errors (e.g. users just typing a statement they want evaluated)
    // the stacktrace will only have one frame.
    // This condition will not catch errors that would be thrown if users type in multi-line
    // statements. For example, if they define a multi-line function.
    // You can try experimenting with this number but there's little guarantee that the other
    // conditions will work. Nevertheless, the checks below also work with multi-frame stacktraces.
    const hasShortStackTrace = stackFrames.length <= 2;
    if (hasShortStackTrace && isSuspiciousError(errorType) && hasSuspiciousFrames(stackFrames)) {
        /// Dropping error due to suspicious stack frames
        return null;
    }

    return event;
};
