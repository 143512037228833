import { setStagingUrls } from './staging';

const ENVS = {
    DEV: {
        code: 'dev',
        matcher: hostname => hostname.includes('localhost') || hostname.includes('10.0.2.2') || hostname.includes('127.0.0.1')
    },
    DEVCONNECTED: {
        code: 'devconnected',
        matcher: hostname => hostname.includes('localdev')
    },
    STAGING: {
        code: 'staging',
        matcher: hostname => hostname.includes('async-stg') || hostname.includes('odyssey-stg') || hostname.includes('login-stg') || hostname.includes('dashboard-stg') || hostname.includes('.appspot.com')
    },
    PRODUCTION: {
        code: 'production',
    }
};

let _ENV_CODE;
export const getEnvCode = () => {
    if (_ENV_CODE) return _ENV_CODE;

    // depending on host, we'll load & return different env
    const env = (() => {

        const getCode = u => u.code;

        // CI
        if (typeof window === 'undefined') return getCode(ENVS.DEV);

        const { hostname } = window.location;
        const envFound = Object.values(ENVS).find(u => u?.matcher?.(hostname));
        if (envFound) return getCode(envFound);

        return getCode(ENVS.PRODUCTION);
    })();

    _ENV_CODE = env;

    return _ENV_CODE;
};

let _ENV;
export const loadEnv = async (_import) => {
    if (_ENV) return _ENV;

    const data = await _import;
    // if staging modify all the URLs that contains -stg to append the selected staging environment
    _ENV = { ...(data.default?.mode === 'staging' ? setStagingUrls(data.default) : data.default) };

    window.__ENV = _ENV;

    return _ENV;
};

export const getEnv = () => {
    if (!_ENV) throw new Error('Error! You can\'t call getEnv before await loadEnv()');
    return _ENV;
};

// for test purpose
export const resetEnv = () => _ENV = undefined;

export const isIsolatedStaging = _url => {
    const url = _url ?? window.location.hostname;
    return url.includes('.appspot.com');
};
