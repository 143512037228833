import { getEnv } from '_config';
import { log } from '_utils/logs';

import { getApp } from '../..';
import type { TrackedEvent, UserProfile } from '../types';

export const microServiceDispatch = async (type: string, tracked: TrackedEvent | UserProfile) => {
    log('[Send Event Payload]', tracked);
    const config = getEnv();
    const app = getApp();
    if (!config || !app) throw new Error('Event tracker has not been initialized');
    if (config.trackingApi) navigator.sendBeacon(config.trackingApi + type, JSON.stringify({ tracked, app }));
};
