import { getEnvCode, getUniverseCode, loadEnv as _loadEnv, getEnv as _getEnv } from '_config';

export const loadEnv = async () => {
    const codeEnv = getEnvCode();
    const codeUniverse = getUniverseCode();
    await _loadEnv(import(`./envs/${codeUniverse}/${codeEnv}.js`));

};

export const getEnv = _getEnv;

let _isAdminApp = false;
export const setIsAdminApp = value => {
    _isAdminApp = value;
};
export const getIsAdminApp = () => _isAdminApp;
