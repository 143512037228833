// Available universes
export const UNIVERSES = {
    WILDCODESCHOOL: {
        id: 1,
        code: 'wcs',
        domains: ['wildcodeschool.com'],
    },
    NEUEFISCHE: {
        id: 2,
        code: 'nf',
        domains: ['neuefische.de', 'wildcodeschool.de'],
    }
};

export const UNIVERSES_VALUES = Object.values(UNIVERSES);
export const ALLOWED_UNIVERSES = UNIVERSES_VALUES.map(({ code }) => code);

let _UNIVERSE_CODE;
export const getUniverseCode = () => {
    if (_UNIVERSE_CODE) return _UNIVERSE_CODE;

    const universe = (() => {

        const getCode = u => u.code;

        // CI
        if (typeof window === 'undefined') return getCode(UNIVERSES.WILDCODESCHOOL);

        // Load universe according to hostname
        const { hostname, search } = window.location;
        const universeFound = UNIVERSES_VALUES.find(u => u?.domains?.some(domain => hostname.includes(domain)));
        if (universeFound) return getCode(universeFound);

        // If we are in localhost/localdev/isolated staging, and an universe CODE is "forced" in query parameter, load this one (and no, we cannot use getEnv, as loadEnv is waiting for loadUniverse)
        if (hostname.includes('localhost') || hostname.includes('localdev') || hostname.includes('.appspot.com')) {
            const forcedUniverse = new URLSearchParams(search).get('universe');
            if (forcedUniverse && ALLOWED_UNIVERSES.includes(forcedUniverse)) return forcedUniverse;
        }

        return getCode(UNIVERSES.WILDCODESCHOOL);
    })();

    _UNIVERSE_CODE = universe;
    return _UNIVERSE_CODE;
};
const getUniverseByKey = ({ key = 'id', value }) => UNIVERSES_VALUES.find(u => u[key] === value);
export const getUniverseById = id => getUniverseByKey({ value: id });
export const getUniverseCodeById = id => getUniverseById(id)?.code;
export const getUniverseByCode = code => getUniverseByKey({ value: code, key: 'code' });
export const getUniverseIdByCode = code => getUniverseByCode(code)?.id;
