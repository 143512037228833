
const STG_ID_REGEX = /(?<=-stg-)\d+(?=\.wildcodeschool)/;
const STG_IDS = ['1', '2', '3'];
// staging by default if there is no staging-env suffix, we use stg-1
const DEFAULT_STG = '1';

let _STG_ID;

//for test purpose
export const resetStgId = () => _STG_ID = undefined;

/**
 * Get the staging env id from the url
 *
 */
export const getStgId = () => {
    if (_STG_ID) return _STG_ID;
    const match = STG_ID_REGEX.exec(window.location.hostname);
    if (match) {
        const stgId = match[0];
        _STG_ID = STG_IDS.includes(stgId) ? stgId : DEFAULT_STG;
        return _STG_ID;
    }
    _STG_ID = DEFAULT_STG;
    return _STG_ID;
};

/**
 * Set the staging env id by replacing the -stg part by -stg-x
 */
export const setStgId = v => v.replace(/(-stg\.wildcodeschool)/, `-stg-${getStgId()}.wildcodeschool`);

/**
 * Set the staging env id by replacing the -stg part by -stg-x in all urls of the ENV object
 */
export const setStagingUrls = env => {
    return Object.fromEntries(Object.entries(env).map(([k, v]) => [
        k,
        typeof v === 'string' ? setStgId(v) : v
    ]));
};
