import { getEnv, getVersion } from '_config';
import { logError as error, log } from '_utils/logs';
import { microServiceDispatch } from './tools/microservice';
import type {
    ApplicationData,
    InitTrackersParams,
    ErrorHandler,
    TrackedEvent,
    UserProfile
} from './types';

let _app: ApplicationData;
export const getApp = () => {
    return _app;
};

let errorHandler: ErrorHandler;
export const init = async ({ appName, version, errorHandler: customErrorHandler }: InitTrackersParams) => {
    _app = {
        name: appName,
        version: version ?? getVersion(),
        universe: getEnv().universe
    };

    if (customErrorHandler) errorHandler = customErrorHandler;
    else errorHandler = await import('./tools/sentry');

    log('[Tracker] Init trackers');
    await Promise.all([
        errorHandler?.init(getEnv()),
    ]);
};

export const setUser = (user: UserProfile) => {
    log('[Tracker] Set user', user);
    const _config = getEnv();
    if (_config.useErrorTracking) errorHandler?.setUser(user);
    // if (_config.useEventTracking) microServiceDispatch('user', user); // Should NOT be sent to MixPanel as it should be done using batch users
};

export const unsetUser = () => {
    log('[Tracker] unset user');
    errorHandler?.unsetUser();
};

export const event = (trackedEvent: TrackedEvent) => {
    const event = {
        ...trackedEvent,
        currentUrl: window.location.href
    };
    log('[Tracker] Event', event);
    const _config = getEnv();
    if (!_config.useEventTracking) return;
    microServiceDispatch('event', event);
};

export const pageView = (userId: number, data: object) => {
    log('[Tracker] Page View', data);
    event({ event: 'pageView', userId, data });
};

export const logError = (err: Error) => {
    log('[Tracker] LogError', err);
    error(err);
    if (getEnv().useErrorTracking) errorHandler?.capture(err);
};

export const logManualError = (err: Error) => {
    log('[Tracker] Manual LogError', err);
    error(err);
    if (getEnv().useErrorTracking) errorHandler?.manualCapture(err);
};
